@import url(vendor/slick.css);
@import url(vendor/fancybox.css);
@import url(vendor/toastr.min.css);
@import url(vendor/all.min.css);
@import url(vendor/justifiedGallery.min.css);
@import url(vendor/prism.css);
.qb-header {
  background-color: #000;
  font-family: inherit;
  border-bottom: 1px solid #404040; }
  .qb-header-logo-block {
    flex: 0 0 160px; }
    .qb-header-logo-block a img {
      height: auto;
      display: block; }
  .qb-header.fixed {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000; }
    .qb-header.fixed + main {
      padding-top: 62px; }
      @media screen and (max-width: 379px) {
        .qb-header.fixed + main {
          padding-top: 57px; } }
  .qb-header nav {
    background-color: transparent; }
    .qb-header nav ul a.active {
      color: #91C934; }
  .qb-header .nav-right {
    order: 3;
    margin-bottom: 0; }
  .qb-header ul {
    background-color: transparent;
    color: #fff;
    font-size: 13px;
    font-weight: 600; }
    .qb-header ul a:hover,
    .qb-header ul a.active {
      color: inherit; }
    .qb-header ul.nav-right {
      text-transform: uppercase;
      display: inline-flex; }
    .qb-header ul a {
      padding: .5rem .75rem; }
  .qb-header .language-switcher {
    position: relative; }
    .qb-header .language-switcher a {
      background-color: #000;
      padding: .5rem 1rem .5rem .75rem;
      min-width: 50px;
      text-align: center; }
      .qb-header .language-switcher a.active-language {
        display: block;
        position: relative; }
        .qb-header .language-switcher a.active-language:after {
          content: "\f078";
          display: block;
          position: absolute;
          font-family: "Font Awesome 5 Free";
          right: 0;
          top: 50%;
          transform: translateY(-40%); }
    .qb-header .language-switcher--dropdown {
      position: absolute;
      display: none;
      z-index: 5;
      max-height: 0;
      transition: all .3s ease-in-out; }
      .qb-header .language-switcher--dropdown li {
        display: block; }
        .qb-header .language-switcher--dropdown li a:hover {
          background-color: #444;
          opacity: 1; }
    @media screen and (max-width: 990px) {
      .qb-header .language-switcher a, .qb-header .language-switcher a.active-language {
        display: inline-block; } }
  .qb-header-hamburger {
    order: 2; }
    .qb-header-hamburger .line {
      stroke: #fff; }
  @media screen and (min-width: 991px) {
    .qb-header .nav-block {
      display: flex;
      flex: 0 0 70%;
      justify-content: space-between; } }
  @media screen and (max-width: 990px) {
    .qb-header .nav-right {
      order: 1; }
    .qb-header nav {
      order: 3;
      position: relative;
      top: 0;
      min-height: calc(100vh - 61px); }
      .qb-header nav ul {
        min-height: 0; } }
  @media screen and (max-width: 379px) {
    .qb-header nav {
      min-height: calc(100vh - 57px); }
    .qb-header-logo-block {
      flex: 0 0 40%; }
    .qb-header-hamburger {
      margin-left: .5rem; }
      .qb-header-hamburger svg {
        height: 38px; } }

body.menu-opened main,
body.menu-opened footer {
  display: none; }

.qb-footer {
  font-family: inherit;
  position: relative;
  z-index: 5; }
  .qb-footer ul {
    margin-bottom: 0; }
  .qb-footer-logo {
    max-width: 164px;
    display: block; }
    .qb-footer-logo img {
      display: block; }
  .qb-footer a {
    font-size: 15px;
    font-weight: 600; }
  .qb-footer .copyright-text {
    font-size: 13px;
    font-weight: 500; }
  .qb-footer-top {
    font-size: 15px; }
  .qb-footer-bottom {
    padding-bottom: 1rem; }
    .qb-footer-bottom ul li {
      margin-right: 1.5rem; }

.slider-wrapper {
  position: relative; }
  .slider-wrapper .prev-arrow,
  .slider-wrapper .next-arrow {
    position: absolute;
    text-decoration: none;
    z-index: 10;
    font-size: 40px;
    padding: 0.5rem 1rem;
    transition: 300ms ease-in-out;
    display: flex;
    text-align: center;
    justify-content: center;
    bottom: 45%; }
    @media (max-width: 991px) {
      .slider-wrapper .prev-arrow,
      .slider-wrapper .next-arrow {
        font-size: 30px; } }
    @media (max-width: 480px) {
      .slider-wrapper .prev-arrow,
      .slider-wrapper .next-arrow {
        font-size: 20px; } }
  .slider-wrapper .prev-arrow {
    left: 1%; }
  .slider-wrapper .next-arrow {
    right: 1%; }

.fw-slider-section .fw-slider.slick-initialized .slick-slide {
  display: flex; }

.fw-slider-section .fw-slider .container {
  flex-grow: 1; }

@media (max-width: 1201px) {
  .fw-slider-section .fw-slider .fw-slider-area-header .h1-style {
    margin-bottom: 0; } }

@media (max-width: 481px) {
  .fw-slider-section .fw-slider .fw-slider-area-header .h1-style {
    font-size: 22px; } }

@media (max-width: 1201px) {
  .fw-slider-section .fw-slider .fw-slider-area-miniheader .h3-style {
    margin-bottom: 0; } }

@media (max-width: 481px) {
  .fw-slider-section .fw-slider .fw-slider-area-miniheader .h3-style {
    font-size: 18px; } }

@media (max-width: 481px) {
  .fw-slider-section .fw-slider .fw-slider-area-text p {
    font-size: 16px;
    line-height: 1.2; } }

.fw-slider-section .btn-burgundy-inverted:hover {
  color: #fff; }

.slick-track {
  display: flex; }
  .slick-track .slick-slide {
    height: inherit; }

@font-face {
  font-family: 'news-iconfont';
  src: url("../../assets/fonts/news-iconfont.eot?uoa5pg");
  src: url("../../assets/fonts/news-iconfont.eot?uoa5pg#iefix") format("embedded-opentype"), url("../../assets/fonts/news-iconfont.ttf?uoa5pg") format("truetype"), url("../../assets/fonts/news-iconfont.woff?uoa5pg") format("woff"), url("../../assets/fonts/news-iconfont.svg?uoa5pg#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block; }

[class^="icon-news-"], [class*=" icon-news-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'news-iconfont' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-news-1:before {
  content: "\e900"; }

.icon-news-2:before {
  content: "\e901"; }

.icon-news-3:before {
  content: "\e902"; }

.icon-news-4:before {
  content: "\e903"; }

.icon-news-5:before {
  content: "\e904"; }

.icon-news-6:before {
  content: "\e905"; }

.icon-news-7:before {
  content: "\e906"; }

html {
  font-size: 18px; }
  @media screen and (max-width: 990px) {
    html {
      font-size: 14px; } }

body {
  font-weight: 500;
  letter-spacing: 0.01em;
  color: #000;
  line-height: 1.6; }

@media screen and (max-width: 990px) {
  section {
    padding: 2rem 0; } }

.btn {
  font-size: calc(11px + 0.1vw);
  min-width: 146px;
  letter-spacing: normal;
  font-weight: 600;
  padding: .8rem 1rem;
  transition: all .3s ease-in-out; }
  @media screen and (min-width: 1919px) {
    .btn {
      font-size: 13px; } }
  @media screen and (max-width: 479px) {
    .btn {
      font-size: 11px; } }
  .btn-blue {
    border: 1px solid #3496F9;
    color: #3496F9; }
    .btn-blue:hover {
      background-color: #3496F9;
      color: #fff; }
    .btn-blue-inverted {
      border: 1px solid #3496F9;
      background-color: #3496F9;
      color: #fff; }
      .btn-blue-inverted:hover {
        border: 1px solid #3496F9;
        background-color: transparent;
        color: #3496F9; }
  .btn-green {
    border: 1px solid #91C934;
    color: #91C934; }
    .btn-green:hover {
      background-color: #91C934;
      color: #fff; }
    .btn-green-inverted {
      border: 1px solid #91C934;
      background-color: #91C934;
      color: #fff; }
      .btn-green-inverted:hover {
        border: 1px solid #91C934;
        background-color: transparent;
        color: #91C934; }

.text-green {
  color: #91C934; }

.text-blue {
  color: #3496F9; }

.text-yellow {
  color: #FFE600; }

.text-black {
  color: #000; }

h1 {
  font-size: 34.28571px; }
  @media screen and (min-width: 480px) {
    h1 {
      font-size: calc(34.28571px + 13.71429 * ((100vw - 480px) / 1440)); } }
  @media screen and (min-width: 1920px) {
    h1 {
      font-size: 48px; } }

h2 {
  font-size: 21.42857px; }
  @media screen and (min-width: 480px) {
    h2 {
      font-size: calc(21.42857px + 8.57143 * ((100vw - 480px) / 1440)); } }
  @media screen and (min-width: 1920px) {
    h2 {
      font-size: 30px; } }

h3 {
  font-size: 18.57143px; }
  @media screen and (min-width: 480px) {
    h3 {
      font-size: calc(18.57143px + 7.42857 * ((100vw - 480px) / 1440)); } }
  @media screen and (min-width: 1920px) {
    h3 {
      font-size: 26px; } }

h4 {
  font-size: 17.14286px; }
  @media screen and (min-width: 480px) {
    h4 {
      font-size: calc(17.14286px + 6.85714 * ((100vw - 480px) / 1440)); } }
  @media screen and (min-width: 1920px) {
    h4 {
      font-size: 24px; } }

h5 {
  font-size: 15.71429px; }
  @media screen and (min-width: 480px) {
    h5 {
      font-size: calc(15.71429px + 6.28571 * ((100vw - 480px) / 1440)); } }
  @media screen and (min-width: 1920px) {
    h5 {
      font-size: 22px; } }

h6 {
  font-size: 14.28571px; }
  @media screen and (min-width: 480px) {
    h6 {
      font-size: calc(14.28571px + 5.71429 * ((100vw - 480px) / 1440)); } }
  @media screen and (min-width: 1920px) {
    h6 {
      font-size: 20px; } }

section ol, section ul, section p, section h1, section h2, section h3, section h4, section h5, section h6 {
  margin-bottom: 2rem; }

h1, h2, h3, h4, h5, h6 {
  font-weight: 700; }

.black-bg {
  background-color: #000; }

a.back-link {
  color: #7A7A7A;
  text-decoration: none;
  font-size: 16px; }
  a.back-link i {
    padding-right: .75rem; }

a.smooth-to-top {
  position: fixed;
  bottom: 5%;
  right: 35px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.7);
  width: 3rem;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  opacity: 0;
  transform: translateY(100px);
  pointer-events: none;
  transition: all .3s ease-in-out; }
  a.smooth-to-top:hover {
    background-color: rgba(204, 204, 204, 0.7); }
  a.smooth-to-top.showSTT {
    opacity: 1;
    transform: translateY(0);
    pointer-events: initial; }

.box-shadow {
  box-shadow: -5px 5px 20px #d3d3d3;
  border-radius: 5px; }
  .box-shadow img {
    border-radius: 5px; }

.filter-shadow {
  filter: drop-shadow(-5px 5px 20px #d3d3d3);
  border-radius: 5px; }
  .filter-shadow img {
    filter: drop-shadow(-5px 5px 20px #d3d3d3); }

.image-block {
  margin-bottom: 1rem;
  text-align: center;
  padding: 1rem;
  background-color: #666666; }
  .image-block img {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.31); }
  @media screen and (min-width: 991px) {
    .image-block img {
      max-width: 80%; } }

.right-image-block {
  margin-bottom: 2rem; }
  @media screen and (min-width: 991px) {
    .right-image-block {
      text-align: right; }
      .right-image-block img {
        max-width: 90%; } }

.cost-calculator {
  padding: 2rem; }
  .cost-calculator--subheader {
    font-size: 70%;
    font-weight: 700; }
  .cost-calculator label {
    display: flex;
    flex-direction: column-reverse; }
    .cost-calculator label span {
      font-size: .9rem;
      transition: all .3s ease-in-out; }
  .cost-calculator input {
    border: none;
    border-bottom: 1px solid #292929;
    font-size: 1.5rem;
    min-height: 1.5rem;
    padding: .25em .5em;
    transition: border-color .4s ease-in-out;
    margin-bottom: 1rem; }
    .cost-calculator input:focus {
      border-color: #3496F9;
      font-weight: 700; }
      .cost-calculator input:focus + span {
        color: #3496F9; }
  .cost-calculator #cost_per_month {
    font-size: 2em;
    color: #3496F9; }
  .cost-calculator #cost_per_month_full_value {
    text-decoration: line-through; }
  @media screen and (min-width: 991px) {
    .cost-calculator {
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16); } }
  .cost-calculator .results-info--plus {
    font-size: 110%; }

.masonry > div > a {
  height: 100%; }

.snippet-category {
  color: #666666;
  letter-spacing: 0.014em;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: .5rem; }
  .snippet-category + h1 {
    margin-bottom: 0.5rem; }

.snippet-text {
  font-size: 15px;
  margin-bottom: .25rem; }

.snippet-date {
  color: #666666;
  font-size: 15px; }

.versions-comparison-table .fa-chevron-down {
  color: #91C934; }

.versions-comparison-table .fa-times {
  color: red; }

.versions-comparison-table table {
  width: 100%;
  background-color: #f7f7f7;
  border: 1px solid #e7e7e7; }
  .versions-comparison-table table th {
    text-align: left;
    font-size: 1.1rem; }
  .versions-comparison-table table td {
    font-size: 0.9rem; }
  .versions-comparison-table table td, .versions-comparison-table table th {
    vertical-align: middle;
    border: none;
    border-bottom: 1px solid #e7e7e7;
    padding: .75rem; }
    .versions-comparison-table table td:not(:first-child), .versions-comparison-table table th:not(:first-child) {
      text-align: center; }

@media screen and (max-width: 990px) {
  .user-manual {
    padding-top: 0; } }

@media screen and (min-width: 991px) {
  .user-manual .row {
    align-items: flex-start; } }

@media screen and (max-width: 990px) {
  .user-manual .row {
    padding-top: 0;
    display: flex;
    flex-direction: column-reverse; } }

.user-manual--scroller {
  padding: 1rem;
  background-color: #F3F3F3; }

.user-manual--nav {
  will-change: min-height;
  flex: 1 0 30%; }
  .user-manual--nav ul {
    font-size: 13px;
    font-weight: 600; }
    .user-manual--nav ul li {
      list-style-type: none;
      margin-left: 0;
      padding-left: 1.5rem;
      position: relative; }
      .user-manual--nav ul li:before {
        position: absolute;
        left: 0;
        top: 0;
        font-family: "Font Awesome 5 Free";
        content: "\f0da";
        font-weight: 900; }
      .user-manual--nav ul li a {
        text-decoration: none; }
  .user-manual--nav h4 {
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 500;
    padding-bottom: .5rem;
    margin-bottom: 1rem;
    position: relative;
    border-bottom: 1px solid #DBDDE1;
    cursor: pointer; }
    .user-manual--nav h4.collapsed-items--header:after {
      transform: translateY(-50%) rotate(-90deg); }
    .user-manual--nav h4:after {
      content: "\f078";
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      color: #B5B5B5;
      transition: all .3s ease-in-out; }

.user-manual--info {
  padding: 0 15px; }

.play--info {
  display: flex;
  flex-direction: column;
  justify-content: space-between; }
  .play--info .bottom-buttons a {
    text-decoration: none; }
    .play--info .bottom-buttons a:not(.btn) {
      font-size: .889rem;
      color: #7A7A7A; }
      .play--info .bottom-buttons a:not(.btn) i {
        padding-left: .75rem; }
  .play--info--standout {
    font-size: 34.28571px;
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
    line-height: normal; }
    @media screen and (min-width: 480px) {
      .play--info--standout {
        font-size: calc(34.28571px + 13.71429 * ((100vw - 480px) / 1440)); } }
    @media screen and (min-width: 1920px) {
      .play--info--standout {
        font-size: 48px; } }
    .play--info--standout span {
      display: block; }
  @media screen and (min-width: 768px) {
    .play--info {
      flex: 0 0 58%; } }
  @media screen and (max-width: 767px) {
    .play--info {
      background-color: #191C21;
      color: #fff;
      padding: 0 5%; } }

@media screen and (min-width: 768px) {
  .play--video {
    flex: 0 0 38%; } }

.play--container--top {
  position: sticky;
  top: 0;
  background-color: #191C21;
  z-index: 10; }

.play--container--heading {
  background-color: #191C21;
  padding-top: 2rem; }
  .play--container--heading h1 {
    margin: 0; }

.play--container--inner {
  background-color: transparent; }

.play--subtitles--item--time {
  display: block; }
  .play--subtitles--item--time strong {
    position: sticky;
    top: 50%; }
    @media screen and (min-width: 769px) and (max-width: 1250px) {
      .play--subtitles--item--time strong {
        top: 65%; } }
    @media screen and (max-width: 767px) {
      .play--subtitles--item--time strong {
        top: 75%; } }

.table-of-contents > p {
  position: relative; }
  .table-of-contents > p:before {
    content: "\f0da";
    color: #B5B5B5;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    font-family: "Font Awesome 5 Free"; }

.contacts--social svg {
  width: 100%; }

@media screen and (max-width: 990px) {
  .contacts--social {
    max-width: 60%; } }

.education-topics .masonry-inner {
  background-color: #262b35;
  font-weight: 700;
  padding: .5rem;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  border-radius: 10px;
  transition: all .3s ease-in-out; }
  .education-topics .masonry-inner:hover {
    background-color: #292929;
    box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.15); }
  .education-topics .masonry-inner-top {
    font-size: 0.88889rem;
    margin-bottom: 1rem; }
    .education-topics .masonry-inner-top .youtube-icon {
      float: right;
      max-width: 15%;
      padding-left: .5rem; }
  .education-topics .masonry-inner-bottom {
    display: flex;
    justify-content: space-between; }
    .education-topics .masonry-inner-bottom .rankinity-icon,
    .education-topics .masonry-inner-bottom .google-icon {
      max-width: 25%; }
  @media screen and (min-width: 991px) {
    .education-topics .masonry-inner {
      min-height: 150px; } }
  @media screen and (max-width: 990px) {
    .education-topics .masonry-inner-top .youtube-icon {
      max-width: 18%; }
    .education-topics .masonry-inner-bottom .rankinity-icon,
    .education-topics .masonry-inner-bottom .google-icon {
      max-width: 30%; } }

.masonry-inner {
  position: relative; }
  .masonry-inner.tags-included {
    padding-bottom: 2.5rem; }
    .masonry-inner.tags-included .tag-line {
      position: absolute;
      left: 15px;
      right: 15px;
      bottom: 15px;
      margin: 0;
      color: #666666; }

.star-block--container {
  display: block;
  margin-bottom: 1rem; }
  @media screen and (max-width: 990px) {
    .star-block--container .btn-container {
      text-align: center; } }
  .star-block--container button {
    display: inline-flex;
    align-items: center;
    margin-left: .5rem; }
    .star-block--container button i {
      padding-right: 1rem;
      margin-left: 0; }
  .star-block--container i {
    margin-left: 2rem;
    display: inline-flex;
    align-items: center;
    color: #91C934;
    font-size: 1.5rem; }
  .star-block--container .rate {
    display: inline-flex; }
    .star-block--container .rate .star-block {
      display: flex;
      width: 2rem;
      position: relative; }
      .star-block--container .rate .star-block:after {
        content: "\f005";
        font-family: "Font Awesome 5 Free";
        font-weight: 400;
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        z-index: 100;
        font-size: 1.5rem;
        pointer-events: none;
        color: #91C934; }
        @media screen and (min-width: 991px) {
          .star-block--container .rate .star-block:after {
            left: 3px; } }
      .star-block--container .rate .star-block label {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        pointer-events: none;
        font-size: 1.5rem;
        padding-bottom: 0; }
      .star-block--container .rate .star-block.colored label:not(.half):before {
        content: "\f005" !important;
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        color: #91C934; }
      .star-block--container .rate .star-block.pasthovered label:not(.half) {
        z-index: 50; }
        .star-block--container .rate .star-block.pasthovered label:not(.half):before {
          content: "\f005" !important;
          font-family: "Font Awesome 5 Free";
          font-weight: 900;
          color: #91C934; }
      .star-block--container .rate .star-block.hovered label:not(.half):before {
        content: "\f005";
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        color: #91C934; }
      .star-block--container .rate .star-block.hovered label.zero-rating-label:before {
        content: "\f005";
        font-family: "Font Awesome 5 Free";
        font-weight: 400;
        color: #91C934; }
      .star-block--container .rate .star-block.hovered ~ .star-block label:before {
        content: "\f005";
        font-family: "Font Awesome 5 Free";
        font-weight: 400;
        color: #91C934; }
    .star-block--container .rate input {
      width: 1rem;
      opacity: 0;
      height: 100%; }
      .star-block--container .rate input:checked + label {
        z-index: 10; }
      .star-block--container .rate input:hover + label {
        z-index: 30; }
    .star-block--container .rate label {
      background-color: #fff; }
      .star-block--container .rate label:before {
        display: block;
        color: transparent;
        content: "\f005";
        font-family: "Font Awesome 5 Free";
        font-weight: 400;
        background-color: #fff; }
      .star-block--container .rate label.half:before {
        content: "\f089";
        font-weight: 900;
        font-family: "Font Awesome 5 Free";
        color: #91C934; }
  @media screen and (max-width: 479px) {
    .star-block--container {
      flex-direction: column; } }

.bg-beige .star-block--container .rate label {
  background-color: #f7f7f7; }
  .bg-beige .star-block--container .rate label:before {
    display: block;
    color: transparent;
    content: "\f005";
    font-family: "Font Awesome 5 Free";
    font-weight: 400;
    background-color: #f7f7f7; }
  .bg-beige .star-block--container .rate label.half:before {
    content: "\f089";
    font-weight: 900;
    font-family: "Font Awesome 5 Free";
    color: #91C934; }

.stars {
  column-gap: 2rem; }
  .stars .current-rating {
    font-weight: 700;
    margin-bottom: 1rem; }
    .stars .current-rating span:first-child {
      font-size: 3.77778rem;
      color: #191C21;
      padding-right: 5px; }
    .stars .current-rating span:last-child {
      font-size: 2.22222rem;
      color: #7d7d7d; }
  @media screen and (min-width: 991px) {
    .stars .col:first-child {
      text-align: right; } }
  @media screen and (max-width: 990px) {
    .stars .col {
      text-align: center; } }

.topical {
  display: flex;
  flex-direction: column; }
  .topical-news {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: space-between; }

.topical, .topical-news {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column; }
  .topical img, .topical-news img {
    min-height: 65px; }
