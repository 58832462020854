//Project specific styles
@import url(vendor/slick.css);
@import url(vendor/fancybox.css);
@import url(vendor/toastr.min.css);
@import url(vendor/all.min.css);
@import url(vendor/justifiedGallery.min.css);
@import url(vendor/prism.css);
@import "variables";
@import "mixins";
@import "header";
@import "footer";
@import "slider";
@import "newsfont";
@import "components";


