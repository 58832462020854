.qb-footer {
    font-family: inherit;
    position: relative;
    z-index: 5;
    ul {
        margin-bottom: 0;
    }
    &-logo {
       max-width: 164px;
       display: block;
        img {
           display: block;
       }
    }
    a {
        font-size: 15px;
        font-weight: 600;
    }
    .copyright-text {
        font-size: 13px;
        font-weight: 500;
    }
    &-top {
        font-size: 15px;
    }
    &-bottom {
        padding-bottom: 1rem;
        ul li {
            margin-right: 1.5rem;
        }
    }
}