.slider-wrapper {
    position: relative;

    .prev-arrow,
    .next-arrow {
        position: absolute;
        text-decoration: none;
        z-index: 10;
        font-size: 40px;
        padding: 0.5rem 1rem;
        transition: 300ms ease-in-out;
        display: flex;
        text-align: center;
        justify-content: center;
        bottom: 45%;

        @media (max-width: 991px) {
            font-size: 30px;
        }

        @media (max-width: 480px) {
            font-size: 20px;
        }
    }
    .prev-arrow {
        left: 1%;
    }
    .next-arrow {
        right: 1%;
    }
}

.fw-slider-section {
    .fw-slider {
        &.slick-initialized {
            .slick-slide {
                display: flex;
            }
        }

        .container {
            flex-grow: 1;
        }

        .fw-slider-area {
            &-header {
                .h1-style {
                    @media (max-width: 1201px) {
                        margin-bottom: 0;
                    }

                    @media (max-width: 481px) {
                        font-size: 22px;
                    }
                }
            }

            &-miniheader {
                .h3-style {
                    @media (max-width: 1201px) {
                        margin-bottom: 0;
                    }

                    @media (max-width: 481px) {
                        font-size: 18px;
                    }
                }
            }

            &-text {
                p {
                    @media (max-width: 481px) {
                        font-size: 16px;
                        line-height: 1.2;
                    }
                }
            }
        }
    }
    .btn-burgundy-inverted {
        &:hover {
            color: $white;
        }
    }
}

.slick-track {
    display: flex;

    .slick-slide {
        height: inherit;
    }
}