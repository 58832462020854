@font-face {
    font-family: 'news-iconfont';
    src:  url('../../assets/fonts/news-iconfont.eot?uoa5pg');
    src:  url('../../assets/fonts/news-iconfont.eot?uoa5pg#iefix') format('embedded-opentype'),
      url('../../assets/fonts/news-iconfont.ttf?uoa5pg') format('truetype'),
      url('../../assets/fonts/news-iconfont.woff?uoa5pg') format('woff'),
      url('../../assets/fonts/news-iconfont.svg?uoa5pg#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
  }
  
  [class^="icon-news-"], [class*=" icon-news-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'news-iconfont' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
  
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  .icon-news-1:before {
    content: "\e900";
  }
  .icon-news-2:before {
    content: "\e901";
  }
  .icon-news-3:before {
    content: "\e902";
  }
  .icon-news-4:before {
    content: "\e903";
  }
  .icon-news-5:before {
    content: "\e904";
  }
  .icon-news-6:before {
    content: "\e905";
  }
  .icon-news-7:before {
    content: "\e906";
  }
  