html {
    font-size: 18px;
    @include bdown(md) {
        font-size: 14px;
    }
}
body {
    // font-family: $montserrat;
    font-weight: 500;
    letter-spacing: 0.01em;
    color: $black;
    line-height: 1.6;
}
section {
    @include bdown(md){
        padding: 2rem 0;
    }
}
.btn {
    @include font-count(11, 13);
    min-width: 146px;
    letter-spacing: normal;
    font-weight: 600;
    // font-family: $montserrat;
    padding: .8rem 1rem;
    @include transition;
    // &-green {
    //     background-color: $green;
    //     color: $white;
    //     border-color: $green;
    //     &:hover {
    //         background-color:$white;
    //         color: $green !important;
    //     }
    // }
    // &-blue {
    //     background-color: $blue;
    //     color: $white;
    //     border-color: $blue;
    //     &:hover {
    //         background-color:$white;
    //         color: $blue !important;
    //     }
    // }
    &-blue {
        border: 1px solid $blue;
        color: $blue;
        &:hover {
            background-color: $blue;
            color: $white;
        }
        &-inverted {
            // border: 1px solid $white;
            border: 1px solid $blue;
            background-color: $blue;
            color: $white;
            &:hover {
                border: 1px solid $blue;
                // background-color: $white;
                background-color: transparent;
                color: $blue;
            }
        }
    }
    &-green {
        border: 1px solid $green;
        color: $green;
        &:hover {
            background-color: $green;
            color: $white;
        }
        &-inverted {
            // border: 1px solid $white;
            border: 1px solid $green;
            background-color: $green;
            color: $white;
            &:hover {
                border: 1px solid $green;
                // background-color: $white;
                background-color: transparent;
                color: $green;
            }
        }
    }
}
.text {
    &-green {
        color: $green;
    }
    &-blue {
        color: $blue;
    }
    &-yellow {
        color: $yellow;
    }
    &-black {
        color: $black;
    }
}
// .container {
//     @include bdown(md){
//         padding: 0 5%;
//     }
// }
h1 {
    @include fluid-font(48px);
}
h2 {
    @include fluid-font(30px);
}
h3 {
    @include fluid-font(26px);
}
h4 {
    @include fluid-font(24px);
}
h5 {
    @include fluid-font(22px);
}
h6 {
    @include fluid-font(20px);
}
section {
    ol, ul, p, h1, h2, h3, h4, h5, h6 {
        margin-bottom: 2rem;
    }
}
h1, h2, h3, h4, h5, h6 {
    font-weight: 700;
}
.black-bg {
    background-color: $black;
}
a.back-link {
    color: #7A7A7A;
    text-decoration: none;
    font-size: 16px;
    i {
        padding-right: .75rem;
    }
}
a.smooth-to-top {
    position: fixed;
    bottom: 5%;
    right: 35px;
    box-shadow: 0 3px 6px rgba($color: #000000, $alpha: .16);
    border-radius: 50%;
    background-color: rgba($color: $white, $alpha: .7);
    width: 3rem;
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    opacity: 0;
    transform: translateY(100px);
    pointer-events: none;
    @include transition;
    &:hover {
        background-color: rgba($color: #ccc, $alpha: .7);
    }
    &.showSTT {
        opacity: 1;
        transform: translateY(0);
        pointer-events: initial;
    }
}

.box-shadow {
    box-shadow: -5px 5px 20px #d3d3d3;
    border-radius: 5px;
    img {
        border-radius: 5px;
    }
}
.filter-shadow {
    filter: drop-shadow(-5px 5px 20px #d3d3d3);
    border-radius: 5px;
    img {
        filter: drop-shadow(-5px 5px 20px #d3d3d3);
    }
}
.image-block {
    margin-bottom: 1rem;
    text-align: center;
    padding: 1rem;
    background-color: $gray;
    img {
        box-shadow: 0 3px 6px rgba($color: #000000, $alpha: .31);
    }
    @include bup(md){
        img {
            max-width: 80%;
        }
    }
}
.right-image-block {
    margin-bottom: 2rem;
    @include bup(md){
        text-align: right;
        img {
            max-width: 90%;
        }
    }
}
.cost-calculator {
    padding: 2rem;
    &--subheader {
        font-size: 70%;
        font-weight: 700;
    }
    label {
        display: flex;
        flex-direction: column-reverse;
        span {
            font-size: .9rem;
            @include transition;
        }
        
    }
    input {
        border: none;
        border-bottom: 1px solid $base;
        font-size: 1.5rem;
        min-height: 1.5rem;
        padding: .25em .5em;
        transition: border-color .4s ease-in-out;
        margin-bottom: 1rem;
        &:focus {
            border-color: $blue;
            font-weight: 700;
            & + span {
                color: $blue;
            }
        }
    }
    #cost_per_month {
        font-size: 2em;
        color: $blue;
    }
    #cost_per_month_full_value {
        text-decoration: line-through;
    }
    @include bup(md){
        box-shadow: 0 3px 6px rgba($color: #000000, $alpha: .16);
    }
    .results-info {
        &--plus {
            font-size: 110%;
        }
    }
}
.masonry > div > a {
    // display: block;
    height: 100%;
}
.snippet {
    &-category {
        color: $gray;
        letter-spacing: 0.014em;
        text-transform: uppercase;
        font-size: 16px;
        font-weight: 600;
        margin-bottom: .5rem;
        & + h1 {
            margin-bottom: 0.5rem;
        }
    }
    &-text {
        font-size: 15px;
        margin-bottom: .25rem;
    }
    &-date {
        color: $gray;
        font-size: 15px;
    }
}
.versions-comparison-table {
    .fa-chevron-down {
        color: $green;
    }
    .fa-times {
        color: red;
    }
    table {
        width: 100%;
        background-color: $beige;
        border: 1px solid #e7e7e7;
        th {
            text-align: left;
            font-size: 1.1rem;
        }
        td {
            font-size: 0.9rem;
        }
        td, th {
            vertical-align: middle;
            border: none;
            border-bottom: 1px solid #e7e7e7;
            padding: .75rem;
            &:not(:first-child){
                text-align: center;
            }
        }
    }
}
.user-manual {
    @include bdown(md){
        padding-top: 0;
    }
    .row {
        @include bup(md){
            align-items: flex-start;
        }
        @include bdown(md){
            padding-top: 0;
            display: flex;
            flex-direction: column-reverse
        }
    }
    &--scroller {
        // overflow: scroll;
        // position: relative;
        // // bottom: 0;
        padding: 1rem;
        background-color: #F3F3F3;
        // transform: translate(0, 0); /* For browsers don't support translate3d. */
        // transform: translate3d(0, 0, 0);
        // will-change: position, transform;
    }
    &--nav {
        // position: sticky;
        // top: 61px;
        // bottom: 0;
        will-change: min-height;
        flex: 1 0 30%;
        ul {
            font-size: 13px;
            font-weight: 600;
            li {
                list-style-type: none;
                margin-left: 0;
                padding-left: 1.5rem;
                position: relative;
                &:before {
                    position: absolute;
                    left: 0;
                    top: 0;
                    font-family: $awesome;
                    content: "\f0da";
                    font-weight: 900;
                }
                a {
                    text-decoration: none;
                }
            }
        }
        h4 {
            font-size: 16px;
            text-transform: uppercase;
            font-weight: 500;
            padding-bottom: .5rem;
            margin-bottom: 1rem;
            position: relative;
            border-bottom: 1px solid #DBDDE1;
            cursor: pointer;
            &.collapsed-items--header {
                &:after {
                    transform:  translateY(-50%) rotate(-90deg);
                }
            }
            &:after {
                content: "\f078";
                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
                font-family: $awesome;
                font-weight: 900;
                color: #B5B5B5;
                transition: all .3s ease-in-out;
            }
        }
    }
    &--info {
        padding: 0 15px;
    }
}

.play {
    &--info {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .bottom-buttons {
            a {
                text-decoration: none;
                &:not(.btn){
                    font-size: .889rem;
                    color: #7A7A7A;
                    i {
                        padding-left: .75rem;
                    }
                }
            }
        }
        &--standout {
            @include fluid-font(48px);
            font-family: $open-sans;
            font-weight: 700;
            line-height: normal;
            span {
                display: block;
            }
        }
        @include bup(sm){
            flex: 0 0 58%;
        }
        @include bdown(sm){
            background-color: #191C21;
            color: #fff;
            padding: 0 5%;
        }
    }
    &--video {
        @include bup(sm){
            flex: 0 0 38%;
        }
    }
    &--container {
        &--top {
            position: sticky;
            top: 0;
            background-color: #191C21;
            z-index: 10;
        }
        &--heading {
            background-color: #191C21;
            padding-top: 2rem;
            h1 {
                margin: 0;
            }
        }
        &--inner {
            background-color: transparent;
        }

    }
    &--subtitles--item--time {
        display: block;
        strong {
            position: sticky;
            top: 50%;
            @include bbetween(sm, nm){
                top: 65%;
            }
            @include bdown(sm) {
                top: 75%;
            }
        }
    }
}
.table-of-contents {
    & > p {
        position: relative;
        &:before {
            content: "\f0da";
            color: #B5B5B5;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            font-family: $awesome;
        }
    }
}

.contacts {
    &--social {
        svg {
            width: 100%;
        }
        @include bdown(md){
            max-width: 60%;
        }
    }
}
.education-topic {
    &s {
        .masonry-inner {
            background-color: #262b35;
            font-weight: 700;
            padding: .5rem;
            color: $white;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            position: relative;
            border-radius: 10px;
            @include transition;
            &:hover {
                background-color: $base;
                box-shadow: 0 6px 16px 0 rgba(0, 0, 0, .15);
            }
            
            &-top {
                @include sfs(16);
                .youtube-icon {
                    float: right;
                    max-width: 15%;
                    padding-left: .5rem;
                }
                margin-bottom: 1rem;
            }
            &-bottom {
                display: flex;
                justify-content: space-between;
                .rankinity-icon,
                .google-icon {
                    max-width: 25%;
                }
            }
            @include bup(md) {
                min-height: 150px;
            }
            @include bdown(md) {
                &-top {
                    .youtube-icon {
                        max-width: 18%;
                    }
                }
                &-bottom {
                    .rankinity-icon,
                    .google-icon {
                        max-width: 30%;
                    }
                }
            }
        }
    }
}
.masonry-inner {
    position: relative;
    &.tags-included {
        padding-bottom: 2.5rem;
        .tag-line {
            position: absolute;
            left: 15px;
            right: 15px;
            bottom: 15px;
            margin: 0;
            color: $gray;
        }
    }
}
.star-block--container {
    display: block;
    margin-bottom: 1rem;
    // display: inline-flex;
    // vertical-align: center;
    @include bdown(md){
        .btn-container {
            text-align: center;
        }
    }
    button {
        display: inline-flex;
        align-items: center;
        margin-left: .5rem;
        i {
            padding-right: 1rem;
            margin-left: 0;
        }
    }
    i {
        margin-left: 2rem;
        display: inline-flex;
        align-items: center;
        color: $green;
        font-size: 1.5rem;
    }
    .rate {
        display: inline-flex;
        .star-block {
            display: flex;
            width: 2rem;
            position: relative;
            &:after {
                content: "\f005";
                font-family: $awesome;
                font-weight: 400;
                position: absolute;
                left: 0;
                @include bup(md){
                    left: 3px;
                }
                top: 0;
                height: 100%;
                width: 100%;
                z-index: 100;
                font-size: 1.5rem;
                pointer-events: none;
                color: $green;
                // text-shadow: 0 1px 3px #137816;
            }
            label {
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                pointer-events: none;
                font-size: 1.5rem;
                padding-bottom: 0;
            }
            &.colored {
                label:not(.half) {
                    &:before {
                        content: "\f005" !important;
                        font-family: $awesome;
                        font-weight: 900;
                        color: $green;
                    }
                }
            }
            &.pasthovered {
                label:not(.half) {
                    z-index: 50;
                    &:before {
                        content: "\f005" !important;
                        font-family: $awesome;
                        font-weight: 900;
                        color: $green;
                    }
                }
            }
            &.hovered {
                label:not(.half) {
                    &:before {
                        content: "\f005";
                        font-family: $awesome;
                        font-weight: 900;
                        color: $green;
                    }
                }
                label.zero-rating-label {
                    &:before {
                        content: "\f005";
                        font-family: $awesome;
                        font-weight: 400;
                        color: $green;
                    }
                }
                & ~ .star-block {
                    // label:not(.half) {
                    //     &:before {
                    //         content: "\f3ae";
                    //         color: $green;
                    //     }
                    // }
                    label {
                        &:before {
                            content: "\f005";
                            font-family: $awesome;
                            font-weight: 400;
                            color: $green;
                        }
                    }
                }
            }
        }
        input {
            // display: none;
            width: 1rem;
            // appearance: none;
            opacity: 0;
            height: 100%;
            // visibility: hidden;
            &:checked + label {
                z-index: 10;
                &:before {
                }
            }
            &:hover {
                & + label {
                    z-index: 30;
                }
            }
        }
        label {
            background-color: #fff;
            &:before {
                display: block;
                color: transparent;
                content: "\f005";
                font-family: $awesome;
                font-weight: 400;
                background-color: #fff;
            }
            &.half {
                &:before {
                    content: "\f089";
                    font-weight: 900;
                    font-family: $awesome;
                    color: $green;
                }
            }
           
        }
        
    }
    @include bdown (xs){
        flex-direction: column;
    }
}
.bg-beige {
    .star-block--container {
        .rate {
            label {
                background-color: $beige;
                &:before {
                    display: block;
                    color: transparent;
                    content: "\f005";
                    font-family: $awesome;
                    font-weight: 400;
                    background-color: $beige;
                }
                &.half {
                    &:before {
                        content: "\f089";
                        font-weight: 900;
                        font-family: $awesome;
                        color: $green;
                    }
                }
                
            }
        }
    }
}
.stars {
    column-gap: 2rem;
    .current-rating {
        font-weight: 700;
        margin-bottom: 1rem;
        span {
            &:first-child {
                @include sfs(68);
                color: #191C21;
                padding-right: 5px;
            }
            &:last-child {
                @include sfs(40);
                color: #7d7d7d;
            }
        }
    }
    @include bup(md){
        .col {
            &:first-child {
                text-align: right;
            }
        }
    }
    @include bdown(md){
        .col {
            text-align: center;
        }
    }
}
.topical {
    display: flex;
    flex-direction: column;

    &-news {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        justify-content: space-between;
    }
}
.topical, .topical-news {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    img {
        min-height: 65px;
    }
}