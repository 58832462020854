
$black: #000;
$white: #fff;
$base: #292929;
$gray: #666666;
$beige: #f7f7f7;
$accent1: #ffdb3c;
$accent2: #720067;
$blue: #3496F9;
$green: #91C934;
$yellow: #FFE600;
$box: 1230px;

//breakpoints
$breakpoints: (
    'hd': 1919px,
    'hg': 1600px,
    'lg': 1400px,
    'nm': 1250px,
    'md': 991px,
    'sm': 768px,
    'xs': 480px,
    'xxs': 380px
)!default;
$bfs: 18;
$montserrat: 'Montserrat', sans-serif;
$open-sans: 'Open Sans' ,sans-serif;
$awesome: 'Font Awesome 5 Free';
// $awesome-alt: 'Font Awesome 5 Pro';