.qb-header {
    background-color: $black;
    font-family: inherit;
    border-bottom: 1px solid #404040;
    &-logo-block {
        flex: 0 0 160px;
        a {
            img {
                height: auto;
                display: block;
            }
        }
    }
    &.fixed {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 1000;
        + main {
            padding-top: 62px;
            @include bdown(xxs){
                padding-top: 57px;
            }
        }
    }
    nav {
        background-color: transparent;
        ul {
            a.active {
                color: $green;
            }
        }
    }
    .nav-right {
        order: 3;
        margin-bottom: 0;
    }
    ul {
        background-color: transparent;
        color: $white;
        font-size: 13px;
        font-weight: 600;
        a:hover,
        a.active {
            color: inherit;
        }
        &.nav-right {
            text-transform: uppercase;
            display: inline-flex;
        }
        a {
            padding: .5rem .75rem;
        }
    }
    .language-switcher {
        position: relative;
        a {
            background-color: $black;
            padding: .5rem 1rem .5rem .75rem;
            min-width: 50px;
            text-align: center;
            &.active-language {
                display: block;
                position: relative;
                &:after {
                    content: "\f078";
                    display: block;
                    position: absolute;
                    font-family: $awesome;
                    right: 0;
                    top : 50%;
                    transform: translateY(-40%);
                }

            }
        }
        &--dropdown {
            position: absolute;
            display: none;
            z-index: 5;
            max-height: 0;
            @include transition;
            li {
                display: block;
                a {
                    &:hover {
                        background-color: #444;
                        opacity: 1;
                    }
                }
            }
        }
        @include bdown(md){
            a, a.active-language {
                display: inline-block;
            }
        }
    }
    &-hamburger {
        order: 2;
        .line {
            stroke: #fff;
        }
    }
    @include bup(md){
        .nav-block {
            display: flex;
            flex: 0 0 70%;
            justify-content: space-between;
        }
    }
    @include bdown(md){
        .nav-right {
            order: 1;
        }
        nav {
            order: 3;
            position: relative;
            top: 0;
            min-height: calc(100vh - 61px);
            ul {
                min-height: 0;
            }
        }
    }
    @include bdown(xxs){
        nav {
            min-height: calc(100vh - 57px);
        }
        &-logo-block {
            flex: 0 0 40%
        }
        &-hamburger {
            margin-left: .5rem;
            svg {
                height: 38px;
            }
        }
    }
}
body.menu-opened {
    
    main,
    footer {
        display: none;
    }
}